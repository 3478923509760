<template>
<div>
    <v-container>
        <v-row>
            <v-col>
                <v-text-field v-model="surveyKey" outlined label="Survey Key"/>
                <v-select v-model="surveyKey" :items="surveyKeys" label="Survey Keys"/>
                <v-btn icon @click="survey().cache(surveyKey,questions)"><v-icon>mdi-content-save</v-icon>SAVE</v-btn>
                <br>
                <v-btn icon @click="questions = survey().loadQuestions(surveyKey)"><v-icon>mdi-briefcase-download</v-icon>LOAD</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6" style="border: 1px solid green; max-height: 1024px; overflow-y: scroll;">
                Questions
                <v-container>
                    <v-row>
                        <v-card>
                            <v-card-actions>
                                <v-btn fab small color="success" @click="newQuestion"><v-icon>mdi-plus</v-icon></v-btn>
                                <v-btn fab small color="warning" @click="resetQuestions"><v-icon>mdi-reload</v-icon></v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-row>
                    <v-row>
                        <v-list dense style="width:100%;">
                            <v-subheader>Questions</v-subheader>
                            <draggable v-model="questions">
                                <v-list-item v-for="(question, qIndex) in questions" :key="'qIndex_'+qIndex">

                                    <v-list-item-avatar>
                                        #{{qIndex+1}}
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title>QID {{question.id}}</v-list-item-title>
                                        <Question :question="question" :survey_answers="{}" :states="{}" :updateCounter="0" :forceDisplay="true" :key="'preview_qid_'+question.id"/>
                                        <!-- <v-card>
                                            <v-card-text>
                                                <div v-html="question.header[language]"/>
                                                <div v-html="question.body[language]"/>
                                                <div v-html="question.subtext[language]"/>
                                                <div v-html="question.footer[language]"/>
                                            </v-card-text>
                                            <v-card-actions></v-card-actions>
                                        </v-card> -->
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-toolbar dense>
                                            <v-btn @click="editQuestion(qIndex)" icon>
                                                <v-icon color="green lighten-1">mdi-pencil</v-icon>
                                            </v-btn>
                                            <v-btn @click="deleteItem(qIndex, questions)" icon>
                                                <v-icon color="green lighten-1" :disabled="questions.length<=1">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-toolbar>                        
                                    </v-list-item-action>
                                    
                                </v-list-item>
                            </draggable>
                        </v-list>
                    </v-row>
                </v-container>

            </v-col>

            <v-col cols="12" md="6" style="border: 1px solid blue;">
                <template v-if="editingQuestion">
                    EDITING: #{{editing.qIndex+1}} | QID: {{editingQuestion.id}}

                    <v-container>
                        <v-row>
                            <v-col>English</v-col>
                            <v-col>French</v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="questions[editing.qIndex].header.text.en" :label="'Header'"/>
                                <v-text-field v-model="questions[editing.qIndex].body.text.en" :label="'Body'"/>
                                <v-text-field v-model="questions[editing.qIndex].subtext.text.en" :label="'Subtext'"/>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="questions[editing.qIndex].header.text.fr" :label="'Header'"/>
                                <v-text-field v-model="questions[editing.qIndex].body.text.fr" :label="'Body'"/>
                                <v-text-field v-model="questions[editing.qIndex].subtext.text.fr" :label="'Subtext'"/>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-switch :true-value="true" :false-value="false" v-model="questions[editing.qIndex].display" :label="'Display'"/>
                    <v-switch :true-value="true" :false-value="false" v-model="questions[editing.qIndex].system" :label="'System'"/>
                    <!-- <LogicEditor v-show="questions[editing.qIndex].hidden" :hidden="questions[editing.qIndex].hidden" :key="'logicEditor_'+questions[editing.qIndex].id" :logics="questions[editing.qIndex].displayLogic" :type="'question'" :item_key="'id'" :item_id="editingQuestion.id" @updateLogics="updateLogics"/> -->
                    <!-- <ActionEditor :key="'actionEditor_'+questions[editing.qIndex].id" :actions="questions[editing.qIndex].actions" :type="'question'" :item_key="'id'" :item_id="editingQuestion.id" @updateActions="updateActions"/> -->

                    <v-list dense style="width:100%;">
                        <v-subheader>
                            Groups
                            <v-btn icon x-small @click="newGroup(questions[editing.qIndex].groups)" color="primary"><v-icon>mdi-plus</v-icon></v-btn>

                        </v-subheader>
                        <draggable v-model="questions[editing.qIndex].groups">
                            <v-list-item v-for="(groupItem, gIndex) in questions[editing.qIndex].groups" :key="'gIndex_'+gIndex">

                                <v-list-item-avatar>
                                    #{{gIndex+1}}
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title>GID {{groupItem.id}}</v-list-item-title>
                                    <v-select v-model="questions[editing.qIndex].groups[gIndex].type" :items="groupTypes" :label="'Input type'" style="width:100%;"/>
                                    <v-switch :true-value="true" :false-value="false" v-model="questions[editing.qIndex].groups[gIndex].display" :label="'Display'"/>

                                    <v-container>
                                        <v-row>
                                            <v-col>English</v-col>
                                            <v-col>French</v-col>
                                        </v-row>  
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="questions[editing.qIndex].groups[gIndex].text.en" :label="'Group Text'"/>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-model="questions[editing.qIndex].groups[gIndex].text.fr" :label="'Group Text'"/>
                                            </v-col>
                                        </v-row>  
                                    </v-container>                                  
                                    
                                    


                                    <v-list>
                                        <v-subheader>
                                            Choices
                                            <v-btn icon x-small @click="newChoice(questions[editing.qIndex].groups[gIndex].choices)" color="primary"><v-icon>mdi-plus</v-icon></v-btn>

                                        </v-subheader>
                                        <draggable v-model="questions[editing.qIndex].groups[gIndex].choices">
                                            <v-list-item v-for="(choiceItem, cIndex) in questions[editing.qIndex].groups[gIndex].choices" :key="'cIndex_'+cIndex">
                                                <v-list-item-avatar>
                                                    #{{cIndex+1}}
                                                </v-list-item-avatar>

                                                <v-list-item-content>
                                                    <v-list-item-title>CID {{choiceItem.id}}</v-list-item-title>

                                                    <v-container>
                                                        <v-row>
                                                            <v-col>English</v-col>
                                                            <v-col>French</v-col>
                                                        </v-row>  
                                                        <v-row>
                                                            <v-col>
                                                                <v-text-field v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].text.en" :label="'Text'"/>
                                                            </v-col>
                                                            <v-col>
                                                                <v-text-field v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].text.fr" :label="'Text'"/>
                                                            </v-col>
                                                        </v-row>  
                                                    </v-container>   

                                                    
                                                    <v-text-field v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].value" :label="'Value'" style="width:50%"/>
                                                    <v-switch :true-value="true" :false-value="false" v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].display" :label="'Display'"/>
                                                    <v-switch :true-value="true" :false-value="false" v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].exclusive" :label="'Exclusive'"/>
                                                    <v-switch :true-value="true" :false-value="false" v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].other" :label="'Other'"/>
                                                    <v-text-field v-if="questions[editing.qIndex].groups[gIndex].choices[cIndex].other" v-model="questions[editing.qIndex].groups[gIndex].choices[cIndex].other_text[language]" :label="'Please specify'"/>
                                                </v-list-item-content>

                                                <v-list-item-action>
                                                    <v-toolbar dense>
                                                        <v-btn icon>
                                                            <v-icon x-small color="primary lighten-1">mdi-pencil</v-icon>
                                                        </v-btn>
                                                        <v-btn @click="deleteItem(cIndex, questions[editing.qIndex].groups[gIndex].choices)" icon :disabled="questions[editing.qIndex].groups[gIndex].choices.length<=1">
                                                            <v-icon x-small color="primary lighten-1">mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-toolbar>                        
                                                </v-list-item-action>
                                            </v-list-item>
                                        </draggable>
                                    </v-list>

                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-toolbar dense>
                                        <v-btn icon>
                                            <v-icon small color="purple lighten-1">mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteItem(gIndex, questions[editing.qIndex].groups)" icon :disabled="questions[editing.qIndex].groups.length<=1">
                                            <v-icon small color="purple lighten-1">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-toolbar>                        
                                </v-list-item-action>
                                
                            </v-list-item>
                        </draggable>
                    </v-list>

                    <v-container>
                        <v-row>
                            <v-col>English</v-col>
                            <v-col>French</v-col>
                        </v-row>  
                        <v-row>
                            <v-col>
                                <v-text-field v-model="questions[editing.qIndex].footer.text.en" :label="'Footer'"/>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="questions[editing.qIndex].footer.text.fr" :label="'Footer'"/>
                            </v-col>
                        </v-row>  
                    </v-container>  
                    
                </template>
            </v-col>
        </v-row>
    </v-container>

</div>
</template>

<script>
import draggable from 'vuedraggable'
import Question from '../Question'

let surveyFunctions = new function(){

    this.cache = function(key,questions){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache') ? JSON.parse(storage.getItem('survey_editor_cache')) : {}

        cache[key] = {
            questions: questions
        };
        
        storage.setItem('survey_editor_cache',JSON.stringify(cache));
    }

    this.loadQuestions = function(key){
        let storage = window.localStorage;
        let cache = storage.getItem('survey_editor_cache');
        if(cache){
            cache = JSON.parse(cache);
            if(cache[key]){
                return cache[key].questions
            }
        }
        return false
    }
}

export default {
    components: {
        Question,
        draggable
    },
    data: function(){
        return {
            surveyKey: 'vte',
            editing: {
                qIndex: null
            },
            config: {
                languages: ['en','fr'],
                groupTypes: ['checkbox','radio','textfield','number','toggle','rating','ranking','calendar','intakeRow']
            },
            questions: []
        }
    },
    methods: {
        resetQuestions: function(){
            this.questions = []
        },
        newQuestion: function(){
            let questions = this.questions
            let output = this.newTemplate('question');
            output.id = this.assignID(questions,'id')
            questions.push(output)
            this.editQuestion(questions.length-1)
            
        },
        newGroup: function(array){
            let output = this.newTemplate('group')
            output.id = this.assignID(array,'id')
            array.push(output)
        },
        newChoice: function(array){
            let output = this.newTemplate('choice')
            output.id = this.assignID(array,'id')
            array.push(output)
        },        
        newTemplate: function(type){
            let languages = this.config.languages
            let output = {}

            switch(type){
                default:
                    console.error('Missing template type')
                break;

                case"question":
                    output = {
                        id: null,
                        header: {text: this.newTemplate('text')},
                        body: {text: this.newTemplate('text')},
                        subtext: {text: this.newTemplate('text')},
                        footer: {text: this.newTemplate('text')},
                        groups: [],
                        display: true,
                        displayLogic: this.newTemplate('displayLogic'),
                        actions: null,
                        system: false
                    }
                    this.newGroup(output.groups)
                break;

                case"group":
                    output = {
                        id: null,
                        type: null,
                        text: this.newTemplate('text'),
                        choices: [],
                        display: true,
                        displayLogic: this.newTemplate('displayLogic')
                    }
                    this.newChoice(output.choices)
                break;

                case"choice":
                    output = {
                        id: null,
                        text: this.newTemplate('text'),
                        value: null,
                        exclusive: false,
                        other: false,
                        other_text: this.newTemplate('text'),
                        display: true,
                        displayLogic: this.newTemplate('displayLogic')
                    }
                    output.other_text.en = "Please specify"
                    output.other_text.fr = "Veuillez préciser"                    
                break;

                case"text":
                    for(let i=0; i<languages.length; i++){
                        let language = languages[i]
                        output[language] = null
                    }
                break;

                case"displayLogic":
                    output = {
                        all: [],
                        any: [],
                        none: []
                    }
                break;
            }


            return output

        },
        updateLogics: function(data){
            let question = this.questions[this.editing.qIndex];
            if(data.type=='question'){
                question.displayLogic = data.displayLogic
            }
        },
        updateActions: function(data){
            console.log('updateActions',data)
            let question = this.questions[this.editing.qIndex];
            if(data.type=='question'){
                question.actions = data.actions
            }
        },
        assignID: function(array, key='id'){
            let ids = []
            for(let i=0; i<array.length; i++){
                let item = array[i]
                if(item[key]){
                    ids.push(item[key])
                }
            }
            ids.sort(function(a, b){return a-b});

            let newID = 1
            for(let i=0; i<ids.length; i++){
                let id = ids[i]
                if(id==newID){
                    newID++
                }
            }

            return newID;
        },
        editQuestion: function(qIndex){
            this.editing.qIndex = qIndex
        },
        deleteItem: function(qIndex, array){
            array.splice(qIndex, 1)
        },
        survey: function(){
            return surveyFunctions
        }   
    },
    computed: {
        surveyKeys: function(){
            let storage = window.localStorage;
            let cache = storage.getItem('survey_editor_cache') ? JSON.parse(storage.getItem('survey_editor_cache')) : {}
            let output = [];

            if(cache){
                for(let key in cache){
                    output.push({
                        text: key,
                        value: key
                    })
                }
            }

            

            return output
        },
        language: function(){
            return 'en'
        },
        editingQuestion: function(){
            let questions = this.questions
            let qIndex = this.editing.qIndex
            if(qIndex!=null){
                return questions[qIndex]
            }else{
                return false
            }
        },
        groupTypes: function(){
            let groupTypes = this.config.groupTypes
            let output = []
            for(let i=0; i<groupTypes.length; i++){
                let item = groupTypes[i]
                output.push({
                    text: item,
                    value: item
                })
            }

            return output
        }
    }
}
</script>

<style>

</style>