<template>
<div>
    <div>
        {{question.header.text.en}}
    </div>
    <div>
        {{question.body.text.en}}
    </div>
    <div style="margin: 5px; font-size: 0.8em;">
        {{question.subtext.text.en}}
    </div>
    <v-list expand>
        <div :disabled="true" :value="true" v-for="group in question.groups" :key="'qid_'+question.id+'_gid_'+group.id">
            <!-- <template v-slot:activator> -->
                <!-- <v-list-item-title>
                    {{group.text.en}}
                </v-list-item-title> -->
            <!-- </template> -->

            <template v-if="group.type=='number'">
                <v-list-item-title>
                    {{group.text}}
                </v-list-item-title>
                <v-list-item-content>
                    <v-text-field v-if="form.group[group.id].value"/>
                </v-list-item-content>
            </template>

            <template v-else-if="group.type=='radio'">
                <v-list-item-title>
                    {{group.text.en}}
                </v-list-item-title>
                <v-radio-group v-model="form.group[group.id].value">
                    <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                        <v-list-item-content>
                            <v-radio dense :value="choice.value ? choice.value : choice.id" :label="choice.text.en"/>
                            <v-text-field v-if="form.group[group.id].value==choice.value && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                        </v-list-item-content>
                    </v-list-item>
                </v-radio-group>
            </template>

            <template v-else-if="group.type=='checkbox'">
                <v-list-item-title>
                    {{group.text.en}}
                </v-list-item-title>
                <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
                    <v-list-item-title>
                        <v-checkbox dense v-model="form.group[group.id].value" :label="choice.text.en" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)"/>
                        <v-text-field v-if="form.group[group.id].value.includes(choice.value) && choice.other" v-model="form.group[group.id].others[choice.id]" :label="'Please specify'"/>
                    </v-list-item-title>
                </v-list-item>
            </template>

            <v-container v-else-if="group.type=='intakeRow'" :key="'row_'+group.id">
                <v-row no-gutters>
                    <v-col cols="6" align-self="center">
                        {{group.text.en}}
                    </v-col>

                    <v-col>
                        <v-checkbox v-model="form.group[group.id].value.diagnosed" :label="group.choices[0].text.en" :value="group.choices[0].value ? group.choices[0].value : group.choices[0].id"/>
                    </v-col>

                    <v-col>
                        <v-radio-group row v-model="form.group[group.id].value.severity" :disabled="!form.group[group.id].value.diagnosed.includes('diagnosed')">
                            <v-radio :value="'mild'" :label="'mild'"/>
                            <v-radio :value="'moderate'" :label="'moderate'"/>
                            <v-radio :value="'severe'" :label="'severe'"/>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </v-container>

            <template v-else>
                Unknown group type: {{group.type}}
            </template>


        </div>
    </v-list>
</div>
</template>

<script>
export default {
    props: {
        question: {
            type: Object,
            required: true
        }
    },
    created: function(){
        let form = this.form
        let question = this.question
        for(let g=0; g<question.groups.length; g++){
            let group = question.groups[g]

            switch(group.type){
                default:
                    this.$set(form.group, group.id, {
                        value: null,
                        others: {}
                    })
                break;

                case"checkbox":
                    this.$set(form.group, group.id, {
                        value: [],
                        others: {}
                    })
                break;

                case"intakeRow":
                    this.$set(form.group, group.id, {
                        value: {
                            diagnosed: [],
                            severity: null
                        },
                        others: {}
                    })
                break;

            }
        }
    },
    data: function(){
        return {
            form: {
                group: {}
            }
        }
    },
    methods: {
        checkboxHandler: function(group, choice){

            if(this.form.group[group.id].value!=null && this.form.group[group.id].value.includes(choice.value)){
                if(choice.exclusive){
                    this.form.group[group.id].value = [choice.value]
                }else{
                    let exclusives = []
                    let filtered = []
                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        if(choice.exclusive){
                            exclusives.push(choice.value)
                        }
                    }
                    for(let v=0; v<this.form.group[group.id].value.length; v++){
                        let value = this.form.group[group.id].value[v]
                        if(!exclusives.includes(value)){
                            filtered.push(value)
                        }
                    }
                    this.form.group[group.id].value = filtered
                }
            }
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style>

</style>